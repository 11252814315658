import React from "react"
import styled from "styled-components"
import check from "../images/icons/contact/check.svg"

export const StyledList = styled.ul`
  margin-top: 32px;
  margin-bottom: 4px;

  @media (min-width: 1024px) {
    margin-top: 40px;
    border-bottom: none;
  }
  & li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (min-width: 1024px) {
      margin-bottom: 24px;
    }
    & img {
      max-width: 20px;
    }
    & p {
      margin-left: 12px;
      margin-bottom: 0;
      line-height: 16px;
      letter-spacing: 0.3px;
      color: #3a465b;
      & span {
        position: relative;
        display: inline-block;
        &::after {
          border-radius: 2px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          content: "";
          width: 100%;
          background-color: #c7dbf5;
          height: 6px;
          z-index: -1;
        }
      }
    }
  }
`

const Advantages = ({ className }) => {
  return (
    <StyledList className={className}>
      <li>
        <img src={check} alt="check icon" />
        <p>
          Lorem <span>ipsum dolor</span> sit consectetur.
        </p>
      </li>
      <li>
        <img src={check} alt="check icon" />
        <p>
          Lorem dolor <span>sit amet</span> consectetur.
        </p>
      </li>
      <li>
        <img src={check} alt="check icon" />
        <p>
          Lorem ipsum dolor sit <span>amet</span>.
        </p>
      </li>
      <li>
        <img src={check} alt="check icon" />
        <p>
          <span>Ipsum</span> dolor sit amet consectetur.
        </p>
      </li>
      <li>
        <img src={check} alt="check icon" />
        <p>
          Lorem ipsum <span>dolor sit </span>amet consectetur.
        </p>
      </li>
    </StyledList>
  )
}

export default Advantages
