import React from "react"
import styled from "styled-components"
import access from "../images/icons/contact/access-time.svg"
import call from "../images/icons/contact/call.svg"
import email from "../images/icons/contact/email.svg"
import Advantages from "./Advantages"
import HeroForm from "./HeroForm"

const StyledSection = styled.section`
  color: #062964;
  & .map {
    width: 100%;
    height: 360px;
    @media (min-width: 1024px) {
      height: 100%;
      border: 1px solid #ddd;
    }
    @media (min-width: 1200px) {
      height: 115%;
      max-height: 700px;
    }
  }
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 240px 0 160px;
  }
  @media (min-width: 1100px) {
    align-items: center;
    max-width: 1570px;
  }
`

const Box = styled.div`
  padding: 32px 16px;
  background-color: #c7dbf5;
  & .contact-adv {
    display: none;
  }
  @media (min-width: 1024px) {
    padding: 50px 16px;
    & .contact-adv {
      display: block;
    }
  }
  @media (min-width: 1100px) {
    padding: 50px 40px;
  }
  @media (min-width: 1200px) {
    padding: 50px 10%;
  }
  @media (min-width: 1400px) {
    padding: 50px 16%;
  }
`

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @media (min-width: 1024px) {
    margin: 0 auto 30px;
  }
  & img {
    margin-right: 8px;
    max-width: 24px;
  }
  & p {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
  }
`

const Contact = () => {
  return (
    <StyledSection id="kontakt">
      <Box>
        <ContentBox>
          <img src={call} alt="Telefon - ikona" />
          <p>
            Telefon: <a href="tel:123456789">123 456 789</a>{" "}
          </p>
        </ContentBox>
        <ContentBox>
          <img src={email} alt="E-mail - ikona" />
          <p>
            <a href="mailto:lorem@ipsum.pl">lorem@ipsum.pl</a>
          </p>
        </ContentBox>
        <ContentBox>
          <img src={access} alt="Godziny otwarcia - ikona" />
          <p>Pon - Ndz: Całodobowo</p>
        </ContentBox>
        <HeroForm />
        <Advantages className="contact-adv" />
      </Box>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.465139705757!2d16.649096015736873!3d50.72986127951489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e374ad0307801%3A0xb6986e98ddc56OS.PL%20-%20Centrum%20Likwidacji%20i%20Obs%C5%82ugi%20Szk%C3%B3d!5e0!3m2!1spl!2spl!4v1584745959486!5m2!1spl!2spl"
        allowFullScreen=""
        frameBorder="0"
        aria-hidden="false"
        className="map"
        title="map"
      />
    </StyledSection>
  )
}

export default Contact
