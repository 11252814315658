import React from "react"
import styled from "styled-components"

export const StyledTitle = styled.h2`
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #062964;
  line-height: 36px;
  padding-bottom: 8px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 2px;
    background-color: #196fd9;
  }
  & span {
    font-weight: 900;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.5px;
    padding-bottom: 10px;
    &::after {
      width: 170px;
    }
  }
`

const Title = ({ children }) => {
  return <StyledTitle>{children}</StyledTitle>
}

export default Title
