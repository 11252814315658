import React from "react"
import styled from "styled-components"
import callicon from "../images/icons/contact/call-icon-shadow.svg"

const StyledForm = styled.form`
  max-width: 1030px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: center;
  width: 100%;
  @media (min-width: 1024px) {
    flex-direction: row;
    position: relative;
    z-index: 20;
    border-radius: 4px;
  }
`

const StyledInput = styled.div`
  width: -webkit-fill-available;
  display: inline-flex;
  align-items: center;
  padding: 13px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  border: solid 2px #3a465b;
  background-color: #fff;
  margin-bottom: 16px;
  & input {
    outline: none;
    background-color: #fff;
    border: none;
    margin-left: 8px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #3a465b;
    }
  }
  @media (min-width: 1024px) {
    width: 230px;
    margin-bottom: 0;
    border-radius: 4px 0 0 4px;
  }
`

const Button = styled.button`
  font-size: 20px;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  background-image: linear-gradient(to left, #195bc5 100%, #196fd9);
  padding: 13px 21px;
  font-weight: 800;
  text-align: left;
  width: 100%;
  transition: all 0.2s ease;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.4px;
  @media (min-width: 1024px) {
    padding: 15.5px 24px;
    width: fit-content;
    border-radius: 0 4px 4px 0;
    font-size: 16px;
  }
`

const HeroForm = () => {
  return (
    <StyledForm action="">
      <StyledInput>
        <img src={callicon} alt="Zadzwoń do nas" />
        <input
          type="tel"
          pattern="^[0-9-+\s()]*$"
          placeholder="Podaj numer telefonu"
          required
        />
      </StyledInput>
      <Button>Zamów kontakt</Button>
    </StyledForm>
  )
}

export default HeroForm
